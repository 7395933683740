

.rating-button:hover{
    background-color: aliceblue !important;
    color: black !important;
}

.rating-button-active{
    background-color: aliceblue !important;
    color: black !important;
}